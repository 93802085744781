import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import "./PricingSection.scss";

function PricingSection(props) {
  const plans = [
    {
      id: "starter",
      name: "Starter",
      price: "$99 / once",
      perks: [
        "Data import or connect with Stripe",
        "View all your current data",
        "Lifetime access to viewing charts of your current data",
      ],
    },
    {
      id: "pro",
      name: "Pro",
      price: "$49 / month",
      perks: [
        "Everything in Starter",
        "Subscriber data updated daily",
        "Use our app in your Stripe dashboard",
      ],
    },
    {
      id: "business",
      name: "Enterprise",
      price: "Contact us",
      perks: [
        "Custom integrations",
        "Custom data analysis",
        "Flexible packages for your goals",
      ],
    },
  ];

  return (
    <Section
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={2}
          spaced={true}
          className="text-center"
        />
        <Row className="justify-content-center">
          {plans.map((plan, index) => (
            <Col
              md={12}
              lg={4}
              className="py-3 d-flex align-items-stretch"
              key={index}
            >
              <Card className="w-100">
                <Card.Body className="d-flex flex-column p-4">
                  <h5 className="font-weight-bold mb-4">{plan.name}</h5>
                  <h1 className="font-weight-bold mb-3">
                    {plan.price}
                    <small className="PricingSection__period"></small>
                  </h1>

                  {plan.description && (
                    <Card.Text className="mb-4">{plan.description}</Card.Text>
                  )}

                  {plan.perks && (
                    <Card.Text as="span" className="mt-2 mb-3">
                      <ul className="list-unstyled">
                        {plan.perks.map((perk, index) => (
                          <li className="py-1" key={index}>
                            <i className="fas fa-check text-success mr-3" />
                            {perk}
                          </li>
                        ))}
                      </ul>
                    </Card.Text>
                  )}

                  <Button
                    variant="primary"
                    size="lg"
                    block={true}
                    href={`/contact`}
                    className="mt-auto"
                  >
                    Choose
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </Section>
  );
}

export default PricingSection;
