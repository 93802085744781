import React, { useEffect, useState } from 'react';
import Switch from 'react-switch';

import Container from "react-bootstrap/Container";
import Dropdown from "react-bootstrap/Dropdown";
import Row from "react-bootstrap/Row";
import Column from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Collapse from 'react-bootstrap/Collapse';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

import { TEST_DATA } from './constants';


ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

// Initiate communication with the stripe client.
// const stripe = new Stripe(STRIPE_API_KEY, {
//   httpClient: createHttpClient(),
//   apiVersion: '2023-10-16',
// })

// look at adding this for the graphs so they don't perk up at the end when
// all the subscriptions are from Looka Developers / PMs
// const MIN_SAMPLES = 10;

function calculateWeightedAverageByDate(testData, chartName) {
  const dateAggregates = {};

  let group = "001";
  // Iterate over each cohortName within the given chartName
  Object.values(testData[chartName]).forEach(cohort => {
    // Aggregate data by date
    (cohort).forEach(record => {
      if (!dateAggregates[record.date]) {
        dateAggregates[record.date] = { totalWeightedRetention: 0, totalCount: 0 };
      }

      if (record.group !== group) {
        group = record.group || "001";
      }

      dateAggregates[record.date].totalWeightedRetention += record.retention * record.count;
      dateAggregates[record.date].totalCount += record.count;
    });
  });

  // Calculate weighted averages and format the result
  const weightedAverages = Object.keys(dateAggregates).map(date => {
    const { totalWeightedRetention, totalCount } = dateAggregates[date];
    const weightedAverage = totalCount > 0 ? totalWeightedRetention / totalCount : 0;
    return { 
      date: parseInt(date),
      retention: weightedAverage,
      identifier: chartName,
      count: totalCount,
      group,
    };
  });

  // Sort results by date if necessary
  weightedAverages.sort((a, b) => a.date - b.date);

  return weightedAverages;
}

function calculateWeightedAverage(testData) {
  const dateAggregates = {};

  let group = "001";
  // Iterate over each cohortName within the given chartName
  Object.values(testData).forEach(chart => {
    Object.values(chart).forEach(cohort => {
      // Aggregate data by date
      (cohort).forEach(record => {
        if (!dateAggregates[record.date]) {
          dateAggregates[record.date] = { totalWeightedRetention: 0, totalCount: 0 };
        }

        if (record.group !== group) {
          group = record.group || "001";
        }

        dateAggregates[record.date].totalWeightedRetention += record.retention * record.count;
        dateAggregates[record.date].totalCount += record.count;
      });
    });
  })

  // Calculate weighted averages and format the result
  const weightedAverages = Object.keys(dateAggregates).map(date => {
    const { totalWeightedRetention, totalCount } = dateAggregates[date];
    const weightedAverage = totalCount > 0 ? totalWeightedRetention / totalCount : 0;
    return { 
      date: parseInt(date),
      retention: weightedAverage,
      identifier: group,
      count: totalCount,
      group,
    };
  });

  // Sort results by date if necessary
  weightedAverages.sort((a, b) => a.date - b.date);

  return weightedAverages;
}

function calculateWeightedAverageByDateForCohort(testData, cohortName) {
  const dateAggregates = {};

  let group = "001";
  // Iterate over each chartName
  Object.keys(testData).forEach(chartName => {
    const cohort = testData[chartName][cohortName];
    if (cohort) {
      // Aggregate data by date for the specified cohort
      cohort.forEach(record => {
        if (!dateAggregates[record.date]) {
          dateAggregates[record.date] = { totalWeightedRetention: 0, totalCount: 0 };
        }

        if (record.group !== group) {
          group = record.group || "001";
        }

        dateAggregates[record.date].totalWeightedRetention += record.retention * record.count;
        dateAggregates[record.date].totalCount += record.count;
      });
    }
  });

  // Calculate weighted averages and format the result
  const weightedAverages = Object.keys(dateAggregates).map(date => {
    const { totalWeightedRetention, totalCount } = dateAggregates[date];
    const weightedAverage = totalCount > 0 ? totalWeightedRetention / totalCount : 0;
    return { 
      date: parseInt(date),
      retention: weightedAverage,
      identifier: cohortName,
      count: totalCount,
      group,
    };
  });

  // Sort results by date if necessary
  weightedAverages.sort((a, b) => a.date - b.date);

  return weightedAverages;
}

function filterByInterval(TEST_DATA, interval) {
  const filteredKeysByInterval = Object.keys(TEST_DATA)
  .filter(key => key.includes(interval));

  const filteredDataByInterval = filteredKeysByInterval.reduce((accumulator, currentKey) => {
      accumulator[currentKey] = TEST_DATA[currentKey];
    return accumulator;
  }, {});

  return filteredDataByInterval;
}

const OPTIONS_AND_VALUES = {
  "Package": [
    {option: "All Packages", value: ""},
    {option: "Starter Yearly", value: "starter_yearly"},
    {option: "Pro Yearly", value: "pro_yearly"}, 
    {option: "Enterprise Yearly", value: "enterprise_yearly"},
    {option: "Starter Monthly", value: "starter_monthly"},
    {option: "Pro Monthly", value: "pro_monthly"},
    {option: "Enterprise Monthly", value: "enterprise_monthly"},
  ],
  "Cohort": [
    {'option': 'All Cohorts', value: ''},
    {'option': 'Jan 2021', 'value': '2021-01'},
{'option': 'Feb 2021', 'value': '2021-02'},
{'option': 'Mar 2021', 'value': '2021-03'},
{'option': 'Apr 2021', 'value': '2021-04'},
{'option': 'May 2021', 'value': '2021-05'},
{'option': 'Jun 2021', 'value': '2021-06'},
{'option': 'Jul 2021', 'value': '2021-07'},
{'option': 'Aug 2021', 'value': '2021-08'},
{'option': 'Sep 2021', 'value': '2021-09'},
{'option': 'Oct 2021', 'value': '2021-10'},
{'option': 'Nov 2021', 'value': '2021-11'},
{'option': 'Dec 2021', 'value': '2021-12'},
{'option': 'Jan 2022', 'value': '2022-01'},
{'option': 'Feb 2022', 'value': '2022-02'},
{'option': 'Mar 2022', 'value': '2022-03'},
{'option': 'Apr 2022', 'value': '2022-04'},
{'option': 'May 2022', 'value': '2022-05'},
{'option': 'Jun 2022', 'value': '2022-06'},
{'option': 'Jul 2022', 'value': '2022-07'},
{'option': 'Aug 2022', 'value': '2022-08'},
{'option': 'Sep 2022', 'value': '2022-09'},
{'option': 'Oct 2022', 'value': '2022-10'},
{'option': 'Nov 2022', 'value': '2022-11'},
{'option': 'Dec 2022', 'value': '2022-12'},
{'option': 'Jan 2023', 'value': '2023-01'},
{'option': 'Feb 2023', 'value': '2023-02'},
{'option': 'Mar 2023', 'value': '2023-03'},
{'option': 'Apr 2023', 'value': '2023-04'},
{'option': 'May 2023', 'value': '2023-05'},
{'option': 'Jun 2023', 'value': '2023-06'},
{'option': 'Jul 2023', 'value': '2023-07'},
{'option': 'Aug 2023', 'value': '2023-08'},
{'option': 'Sep 2023', 'value': '2023-09'},
{'option': 'Oct 2023', 'value': '2023-10'},
{'option': 'Nov 2023', 'value': '2023-11'},
],
"Interval": [
  {option: "All Intervals", value: ""},
  {option: "Month", value: "month"},
  {option: "Year", value: "year"}
]
}

function addGroupToEveryDataRecord(testData, groupName) {
  const testDataCopy = JSON.parse(JSON.stringify(testData));

  Object.keys(testDataCopy).forEach(chartName => {
    Object.keys(testDataCopy[chartName]).forEach(cohortName => {
      testDataCopy[chartName][cohortName].forEach((dataRecord, index) => {
        if (dataRecord.date > 32) {
          delete testDataCopy[chartName][cohortName][index]
        }
      })
    })
  });

  Object.keys(testDataCopy).forEach(chartName => {
    Object.keys(testDataCopy[chartName]).forEach(cohortName => {
      testDataCopy[chartName][cohortName].forEach(dataRecord => {

        if (!dataRecord.group) {
          const retention = dataRecord.retention;
          if (retention < 30 && cohortName.includes('year')) {
            dataRecord.retention =  Math.min(retention * 0.75, 45);
          } else if (retention < 70 && cohortName.includes('year')) {
            dataRecord.retention = Math.min(retention * 1.5, 85);
          }

          // if (dataRecord.date > 30) {
          //   dataRecord.retention =  retention * 0.2 //Math.min(retention * 0.2, 5);
          // }
        dataRecord.group = groupName; // Add or update the group field
        }
      });
    });
  });
  return testDataCopy
}

/*
What do I need?
* 
*/


/**
 * This is a view that is rendered in the Stripe dashboard's customer detail page.
 * In stripe-app.json, this view is configured with stripe.dashboard.customer.detail viewport.
 * You can add a new view by running "stripe apps add view" from the CLI.
 */
const StripeApp = () => {

  // const refreshDashboardData = useRefreshDashboardData();
  // const id = environment && environment.objectContext && environment.objectContext.id;

  const [ data, setData ] = useState([]);
  const [ chartName, setChartName ] = useState('');
  const [ cohortName, setCohortName ] = useState('');
  const [ subscriptionInterval, setSubscriptionInterval ] = useState('');

  const [ comparison, setComparison ] = useState(false);

  const [ comparisonData, setComparisonData ] = useState([]);
  const [ comparisonChartName, setComparisonChartName ] = useState('');
  const [ comparisonCohortName, setComparisonCohortName ] = useState('');
  const [ comparisonSubscriptionInterval, setComparisonSubscriptionInterval ] = useState('');
  const [open, setOpen] = useState(false);

  // Prepare data to display to user
  useEffect(() => {
    const tempData = addGroupToEveryDataRecord(TEST_DATA, "001");

    // Weighted average of all subscriptions
    if (!chartName && !cohortName && !subscriptionInterval) {
      const weightedAverage = calculateWeightedAverage(tempData);
      setData(weightedAverage);

    // Weighted average of all subscriptions over one cohort 
    } else if (!chartName && !subscriptionInterval) {
      const weightedAverage = calculateWeightedAverageByDateForCohort(tempData, cohortName);
      setData(weightedAverage);

    // Weighted average of all cohorts over one product
    } else if (!cohortName && !subscriptionInterval) {
      const weightedAverage = calculateWeightedAverageByDate(tempData, chartName);
      setData(weightedAverage);

    // Weighted average of all subscriptions charged by interval over one cohort
    } else if (cohortName && subscriptionInterval) {

      const weightedAverage = calculateWeightedAverageByDateForCohort(
        filterByInterval(tempData, subscriptionInterval),
        cohortName
      );
      setData(weightedAverage);

    // Weighted average of all subscriptions charged by interval
    } else if (subscriptionInterval) {
      const weightedAverage = calculateWeightedAverage(filterByInterval(tempData, subscriptionInterval));
      setData(weightedAverage);

    // Retention data of a specific cohort on one product
    } else {
      setData(tempData[chartName][cohortName]);
    }
  }, [chartName, cohortName, subscriptionInterval, setData])

  // Comparison Chart
    // Prepare comparison data to display to user
    useEffect(() => {
      const tempData = addGroupToEveryDataRecord(TEST_DATA, "002");

      if (!comparison) { return; }
  
      // Weighted average of all subscriptions
      if (!comparisonChartName && !comparisonCohortName && !comparisonSubscriptionInterval) {
        const weightedAverage = calculateWeightedAverage(tempData);
        setComparisonData(weightedAverage);
  
      // Weighted average of all subscriptions over one cohort 
      } else if (!comparisonChartName && !comparisonSubscriptionInterval) {
        const weightedAverage = calculateWeightedAverageByDateForCohort(tempData, comparisonCohortName);
        setComparisonData(weightedAverage);
  
      // Weighted average of all cohorts over one product
      } else if (!comparisonCohortName && !comparisonSubscriptionInterval) {
        const weightedAverage = calculateWeightedAverageByDate(tempData, comparisonChartName);
        setComparisonData(weightedAverage);
  
      // Weighted average of all subscriptions charged by interval over one cohort
      } else if (comparisonCohortName && comparisonSubscriptionInterval) {
  
        const weightedAverage = calculateWeightedAverageByDateForCohort(
          filterByInterval(tempData, comparisonSubscriptionInterval),
          comparisonCohortName
        );
        setComparisonData(weightedAverage);
  
      // Weighted average of all subscriptions charged by interval
      } else if (comparisonSubscriptionInterval) {
        const weightedAverage = calculateWeightedAverage(filterByInterval(tempData, comparisonSubscriptionInterval));
        setComparisonData(weightedAverage);
  
      // Retention data of a specific cohort on one product
      } else {
        setComparisonData(tempData[comparisonChartName][comparisonCohortName]);
      }
    }, [comparison, comparisonChartName, comparisonCohortName, comparisonSubscriptionInterval, setComparisonData])

  const onChangeComparisonSwitch = () => {
    setComparison(!comparison);
  }

  const options = {
    responsive: true,
    scales: {
      y: {
        title: {
          display: true,
          text: 'Retention %'
        }
      },
      x: {
        title: {
          display: true,
          text: 'Months since subscription start'
        }
      }
    },     
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Retention analysis',
      },
      
    },
  };

  function transformDataForChartJS(data) {
    if (!data || data.length === 0) return { data: [], label: '' };

     return {
      labels: data[0].map((record) => record.date),
      datasets: data.map((recordSet, index) => {
        return {
            data: recordSet.map((record) => record.retention),
            label: recordSet[0].identifier,
            borderColor: index === 0 ? '#F78154' : '#B4436C',
          }
        })
      }
  }


  // do the interval calculation
  // populate the select Rowes automatically
  function CustomSelectRow(
    label,
    name,
    optionsAndValues, 
    handler,
    defaultValue,
    disabled=false
  ){
    
    const selectedOption = optionsAndValues && optionsAndValues.find(option => option.value === defaultValue);
    return (
      <div style={{margin: '5px'}}>
      <Dropdown onToggle={(isOpen, event) => {
          // Prevent dropdown from toggling when disabled
          if (disabled) {
            event.preventDefault();
          }
        }}>
        <Dropdown.Toggle variant="success" id={`dropdown-basic-${name}`} disabled={disabled}>
          {selectedOption ? selectedOption.option : `Choose ${name}`}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {optionsAndValues.map((option, index) => (
            <Dropdown.Item
              key={index}
              eventKey={option.value}
              disabled={disabled}
              active={defaultValue === option.value}
              onClick={() => !disabled && handler(option.value)}
            >
              {option.option}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
      </div>
    );
  }

  return (
      <Container className="text-center">
          {data && data.length > 0 && (
            <>
            <Row className="justify-content-center">
              <Column md={12}>
                <h2 className="text-center py-3">Cohort Retention Demo</h2> {/* Title added here */}
                <div className="text-center py-3">
                  <Button
                    onClick={() => setOpen(!open)}
                    aria-controls="example-collapse-text"
                    aria-expanded={open}
                  >Show description</Button>
                  <Collapse in={open}>
                    <ol style={{textAlign: 'left', margin: '5px'}}>
                      <li>We group your subscribers into cohorts based on when they signed up - the cohort date.</li>
                      <li>Below you see a demo of subscriber retention based on how many months subscribed</li>
                      <li>Retention % of 100 means all initial subscribers are still subscribed</li>
                      <li>Retention % of 30 means only 30% of initial subscribers are still subscribed</li>
                      <li>You can break the data down further by subscription interval, package, and cohort date</li>
                    </ol>
                  </Collapse>
                </div>
              </Column>
            </Row>
            <Row className="justify-content-center">
              
              <Column md={6} lg={6} className="py-3">
                <Row id="base selects" className="justify-content-center mb-3" >
                <div className="py-2">
                  {CustomSelectRow(
                    "Interval-001",
                    "Interval",
                    OPTIONS_AND_VALUES["Interval"],
                    setSubscriptionInterval,
                    subscriptionInterval,
                    Boolean(chartName),
                  )}
                </div>
                <div className="py-2">
                  {CustomSelectRow(
                    `Package-001`,
                    "Package",
                    OPTIONS_AND_VALUES["Package"],
                    setChartName,
                    chartName,
                    Boolean(subscriptionInterval)
                  )}
                </div>
                <div className="py-2">
                  {CustomSelectRow(
                    `Cohort-001`,
                    "Cohort",
                    OPTIONS_AND_VALUES["Cohort"],
                    setCohortName,
                    cohortName,
                    false
                  )}
                </div>
              </Row>
              <Row id="comparison-switch" className="justify-content-center mb-3" >
                <div className="pr-2">Compare</div> {/* Label for the switch */}
                <Switch onChange={onChangeComparisonSwitch} checked={comparison} />
              </Row>
            </Column>
            <Column md={6} lg={6} className="py-3"> 
            {comparison && (
              <Row id="comparison-selects" className="justify-content-center">
                 <div className="py-2">
                  {CustomSelectRow(
                    `Interval-002`,
                    "Interval",
                    OPTIONS_AND_VALUES["Interval"],
                    setComparisonSubscriptionInterval,
                    comparisonSubscriptionInterval,
                    Boolean(comparisonChartName)
                  )}
                </div>
                <div className="py-2">
                  {CustomSelectRow(
                    `Package-002`,
                    "Package",
                    OPTIONS_AND_VALUES["Package"],
                    setComparisonChartName,
                    comparisonChartName,
                    Boolean(comparisonSubscriptionInterval)
                  )}
                </div>
                <div className="py-2">
                  {CustomSelectRow(
                    `Cohort-002`,
                    "Cohort",
                    OPTIONS_AND_VALUES["Cohort"],
                    setComparisonCohortName,
                    comparisonCohortName,
                    false
                  )}
                </div>
              </Row>
            )}
            </ Column>
            {(comparison && comparisonData && comparisonData.length > 0) ? (
              <Line data={transformDataForChartJS([data, comparisonData])} options={options} />
            
            ) : (
            <Line data={transformDataForChartJS([data])} options={options} />
          )}
          </Row>
          </>
          )}
      </Container>
  );
};

export default StripeApp;
