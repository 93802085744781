import React from "react";
import Meta from "./../components/Meta";
import HeroSection from "./../components/HeroSection";
import FeaturesSection from "./../components/FeaturesSection";
import ContactSection from "../components/ContactSection";

function IndexPage(props) {
  return (
    <>
      <Meta />
      <HeroSection
        bg="white"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="Increase your MRR by understanding your subscribers"
        subtitle="Quickly view all your subscribers pre broken into cohorts. Find where churn happens and what packages are winners."
        image="https://s3.amazonaws.com/cdn.robotlogogenerator.com/Hero+Image.png"
        buttonText="Submit"
        buttonColor="primary"
      />
      <FeaturesSection
        bg="white"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="How it works"
        subtitle="Save your data team dozens of hours of work. Connect your data and you can instantly see how well your subscription packages are performing so you can make the best decisions for your business."
      />
      <ContactSection
        bg="white"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="Contact Us"
        subtitle=""
        buttonText="Send message"
        buttonColor="primary"
        showNameField={true}
      />

    </>
  );
}

export default IndexPage;
