import React from "react";
import Meta from "./../components/Meta";
import StripeApp from './../components/Stripe';

function StripePage(props) {
  return (
    <>
      <Meta title="Demo" />
      <StripeApp />
    </>
  );
}

export default StripePage;
